// @ts-ignore
import {Dimensions, StyleSheet, TextStyle, ViewStyle} from 'react-native';

const {width: screenWidth} = Dimensions.get('window');

interface Style {
  item: ViewStyle
  title: TextStyle
}

export const styles: Style = StyleSheet.create<Style>({
  item: {
    width: Math.floor((screenWidth - 30) / 2),
    alignItems: 'center',
    paddingTop: 42,
    alignContent: 'center',
    height: 145,
    marginBottom: 15,
    paddingHorizontal: 44,
  },
  title: {
    marginTop: 10,
    textAlign: 'center',
  },

});
