// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import { colors } from '../Core/theme/colors';

interface Style {
  containerLight: ViewStyle
  containerDark: ViewStyle
  header: ViewStyle
  actionContainer: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  containerLight: {
    padding: 15,
    backgroundColor: colors.grey["200"],
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.grey["400"],
  },
  containerDark: {
    padding: 15,
    backgroundColor: colors.grey["300"],
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.grey["400"],
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  actionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
