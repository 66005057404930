import { Playground, Props } from 'docz';
import { Box } from '@components/Box';
import { Text, View } from 'react-native';
import * as React from 'react';
export default {
  Playground,
  Props,
  Box,
  Text,
  View,
  React
};