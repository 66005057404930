// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  avatar: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginTop: 20,
    flexDirection: 'row',
  },
  avatar: {
    marginRight: 5,
  },
  content: {
    flex: 1,
    paddingHorizontal: 5,
  },
});
