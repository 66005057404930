import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostCommentPlaceholder } from '@components/PostCommentPlaceholder';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  PostCommentPlaceholder,
  React
};