// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';
import {colors} from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    paddingHorizontal: 15,
    paddingBottom: 20,
    backgroundColor: colors.background.white,
    flex: 1,
  },
  content: {
    // padding: 20,
    borderRadius: 15,
    backgroundColor: colors.background.white,
  }
});
