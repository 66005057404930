// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';
import { colors } from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  contentLeft: ViewStyle
  contentRight: ViewStyle
  column: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 15,
    backgroundColor: colors.grey["150"],
    alignItems: 'center',
  },
  contentLeft: {
    flex: 1,
  },
  contentRight: {
    flexDirection: 'row',
  },
  column: {
    paddingHorizontal: 5,
  },
});
