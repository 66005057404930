export const colors = {
  primary: {
    main: '#3BB64D',
    dark: '#02B875',
    light: '#DFECE5',
  },
  info: {
    main: '#352097',
    light: '#5E29CD',
  },
  warning: {
    main: '#FFA94D',
  },
  error: {
    main: '#E76049',
  },
  text: {
    primary: '#2F3542',
    secondary: '#A4B0BF',
    white: '#FFFFFF',
  },
  background: {
    default: '#F1F2F6',
    white: '#FFFFFF',
    black: '#000000',
  },
  actions: {
    active: '#3BB64D',
    disabled: '#DFE4EA',
  },
  social: {
    facebook: '#395899',
    google: '#437FF3',
    apple: '#000000',
  },
  grey: {
    100: '#F8F8FA',
    150: '#F8F8FB',
    200: '#F1F2F6',
    205: '#F1F2F680',
    300: '#DFE4EA',
    400: '#CED6E0',
    500: '#A4B0BF',
    700: '#57606F',
    800: '#90939C',
    900: '#2F3542',
    905: '#2F354250',
  },
}
