import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {Text} from "../../../../../Text";
import {colors} from "../../../../../Core/theme/colors";
import {VenueType} from "../../../../../Core/types/Venue";
import {styles} from "./styles";

interface VenueNameProps {
  venue: VenueType | null
}

export const VenueName: FunctionComponent<VenueNameProps> = ({
  venue,
}) => {
  if (!venue) {
    return null;
  }
  return (
    <View style={styles.venueContainer}>
      <Text type="body" color={colors.text.secondary} numberOfLines={1}>
        {venue.name}
      </Text>
    </View>
  )
}
