import React, {FunctionComponent, Fragment} from "react";
// @ts-ignore
import {View} from 'react-native';

import {Text} from "../../../../../Text";
import {styles} from "./styles";

import {CompetitionType} from "../../../../../Core/types/Competition";
import {SeasonType} from "../../../../../Core/types/Season";
import {StageType} from "../../../../../Core/types/Stage";
import {colors} from "../../../../../Core/theme/colors";

import {CompetitionName} from './CompetitionName';
import {SeasonName} from './SeasonName';
import {StageName} from './StageName';


interface CompetitionInterface {
  competition: CompetitionType | null
  season: SeasonType | null
  stage: StageType | null
}

export const Competition: FunctionComponent<CompetitionInterface> = ({competition, season, stage}) => {
  if (!competition) {
    return null;
  }
  return (
    <View style={styles.competition}>
      <Text type="body" color={colors.grey["800"]} numberOfLines={1} style={styles.contentContainer}>
        <CompetitionName competition={competition} />
        <SeasonName season={season} />
        <StageName stage={stage} />
      </Text>
    </View>
  )
}
