import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {colors} from "../../../../../Core/theme/colors";
import {VenueType} from "../../../../../Core/types/Venue";
import {Icon} from "../../../../../Icon";
import {CityType} from "../../../../../Core/types/City";
import {CountryName} from "./Country";

interface VenueProps {
  city: CityType | null
}

export const CityName: FunctionComponent<VenueProps> = ({
  city,
}) => {
  if (!city) {
    return null;
  }
  const {country} = city;
  return (
    <View style={styles.contentContainer}>
      <Icon name="point" color={colors.grey["905"]} styleContainer={styles.iconContainer} />
      <Text type="body" color={colors.text.secondary}>{city.name}</Text>
      <CountryName country={country} />
    </View>
  )
}
