import { Playground, Props } from 'docz';
import { DetailsItem, Flag, TeamAvatar, UsersAvatars } from '@components';
import * as React from 'react';
export default {
  Playground,
  Props,
  DetailsItem,
  Flag,
  TeamAvatar,
  UsersAvatars,
  React
};