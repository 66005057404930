import React, {ElementType, FunctionComponent} from 'react';

import {Header} from "./Header";
import {VenueType} from "../Core/types/Venue";
import {VenueAvatar} from "../Avatar/VenueAvatar";

const defaultCoverUri = require('./assets/venueCover.jpg');

interface HeaderUserProps {
  venue: VenueType
  onPressBackButton?: () => void
  ImageComponent?: ElementType,
}

export const HeaderVenue: FunctionComponent<HeaderUserProps> = ({
  venue,
  onPressBackButton,
  ImageComponent,
}) => {
  const coverUri = venue.image ? {uri: venue.image} : defaultCoverUri;
  const cloneVenue = {...venue}
  cloneVenue.image = undefined;
  return (
    <Header
      avatar={<VenueAvatar venue={cloneVenue} size="xxLarge" rounded />}
      title={venue.name}
      coverUri={coverUri}
      onPressBackButton={onPressBackButton}
      ImageComponent={ImageComponent}
    />
  )
}
