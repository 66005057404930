import { StyleSheet, TextStyle, ViewStyle } from 'react-native';

import { colors } from '../Core/theme/colors'
import { fonts } from '../Core/theme/fonts'

interface Style {
  container: ViewStyle
  avatar: ViewStyle
  input: TextStyle
  placeholder: TextStyle
  button: ViewStyle
  loading: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    padding: 10,
    borderTopWidth: 0.5,
    borderColor: colors.grey[400],
    alignItems: 'center',
  },
  avatar: {
    marginRight: 5,
  },
  input: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    flex: 1,
    marginRight: 5,
    fontFamily: fonts.regular.fontFamily,
    fontWeight: fonts.regular.fontWeight,
    fontStyle: fonts.regular.fontStyle,
    fontSize: fonts.regular.fontSize,
    color: colors.text.primary,
    lineHeight: 14,
  },
  placeholder: {
    fontFamily: fonts.italic.fontFamily,
    fontWeight: '400',
    fontStyle: fonts.italic.fontStyle,
    fontSize: fonts.italic.fontSize,
    color: colors.text.secondary,
    lineHeight: 12,
  },
  button: {
    height: 30,
    width: 72,
    borderRadius: 15,
  },
  loading: {
    marginLeft: 5,
  },
});
