import React, {FunctionComponent, useState, Fragment, useEffect} from "react";
// @ts-ignore
import {Pressable, View} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../Text";

interface MessageInterface {
  message: string | null
}

const STATUS_INIT = 'INIT';
const STATUS_TRIM = 'TRIM';
const STATUS_FULL = 'FULL';

export const Message: FunctionComponent<MessageInterface> = ({
  message,
}) => {
  const [status, setStatus] = useState(STATUS_INIT);

  const [fullTextHeight, setFullTextHeight] = useState(0);
  const [trimmedTextHeight, setTrimmedTextHeight] = useState(0);

  useEffect(() => {
    if (status === STATUS_INIT && fullTextHeight && trimmedTextHeight) {
      const newStatus = trimmedTextHeight < fullTextHeight ? STATUS_TRIM : STATUS_FULL;
      setStatus(newStatus);
    }
  }, [fullTextHeight, trimmedTextHeight])

  if (!message) {
    return null;
  }

  const onLayoutTrimmedText = (event) => {
    setTrimmedTextHeight(event.nativeEvent.layout.height);
  }

  const onLayoutFullText = (event) => {
    setFullTextHeight(event.nativeEvent.layout.height);
  }

  const handleContinueReading = () => {
    setStatus(STATUS_FULL);
  }

  return (
    <View style={styles.container}>
      <View
        style={[STATUS_INIT, STATUS_TRIM].includes(status) ? styles.content : styles.hide}
        onLayout={onLayoutTrimmedText}
      >
        <Text style={styles.text} type="body" numberOfLines={3}>{message}</Text>
        <Pressable onPress={handleContinueReading}>
          <Text style={styles.text} type="info">Continue reading</Text>
        </Pressable>
      </View>
      <View style={[STATUS_INIT, STATUS_FULL].includes(status) ? styles.content : styles.hide}
            onLayout={onLayoutFullText}>
        <Text style={styles.text} type="body">{message}</Text>
      </View>
    </View>
  )
}
