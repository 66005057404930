// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';
import { colors } from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  containerLight: ViewStyle
  containerDark: ViewStyle
  content: ViewStyle
  title: TextStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    borderRadius: 15,
    padding: 15,
    alignItems: 'center',
  },
  containerLight: {
    backgroundColor: colors.background.white,
  },
  containerDark: {
    backgroundColor: colors.grey["200"],
  },
  title: {
    marginBottom: 5,
  },
  content: {
    flex: 1,
    marginLeft: 15,
  }
});
