import React from 'react';

// @ts-ignore
import {TextInput} from 'react-native';

export const SearchBar = () => {
  return (
    <TextInput />
  )
}
