import React, {FunctionComponent} from 'react';
// @ts-ignore
import { TextInput, TextInputProperties, View } from 'react-native';

import {styles} from './styles';
import { colors } from '../Core/theme/colors';
import { Icon } from '../Icon';


interface InputProps extends TextInputProperties {
  /**
   * Disables the input component
   * @default false
   */
  disabled?: boolean,

  /**
   * Displays an icon on the left
   */
  icon?: string,

  /**
   * Callback function when pressing component. (optional)
   */
  onClear?: () => void;

}

export const Input: FunctionComponent<InputProps> = ({
  disabled = false,
  onClear,
  icon,
  ...props
}: InputProps) => {
  return (
    <View style={styles.container}>
      {icon && (
        <View style={styles.icon}>
          <Icon name={icon} color={colors.text.secondary} />
        </View>
      )}
      <TextInput
        {...props}
        editable={!disabled}
        underlineColorAndroid="transparent"
        style={styles.input}
        placeholderTextColor={colors.text.secondary}
      />
      {onClear && (
        <View style={styles.iconClear}>
          <Icon name="clear" color={colors.text.secondary} onPress={onClear} />
        </View>
      )}
    </View>
  )
}
