import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";
import {Header} from "./components/Header";
import {Gallery} from "./components/Gallery";
import {Footer} from "./components/Footer";

export const PostPlaceholder: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Header />
        <Gallery />
        <Footer />
      </View>
    </View>
  )
}
