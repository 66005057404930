// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-components-avatar-mdx": () => import("./../../../docs/components/Avatar.mdx" /* webpackChunkName: "component---docs-components-avatar-mdx" */),
  "component---docs-components-bottom-sheet-item-mdx": () => import("./../../../docs/components/BottomSheetItem.mdx" /* webpackChunkName: "component---docs-components-bottom-sheet-item-mdx" */),
  "component---docs-components-box-mdx": () => import("./../../../docs/components/Box.mdx" /* webpackChunkName: "component---docs-components-box-mdx" */),
  "component---docs-components-button-mdx": () => import("./../../../docs/components/Button.mdx" /* webpackChunkName: "component---docs-components-button-mdx" */),
  "component---docs-components-card-mdx": () => import("./../../../docs/components/Card.mdx" /* webpackChunkName: "component---docs-components-card-mdx" */),
  "component---docs-components-comment-box-mdx": () => import("./../../../docs/components/CommentBox.mdx" /* webpackChunkName: "component---docs-components-comment-box-mdx" */),
  "component---docs-components-details-item-mdx": () => import("./../../../docs/components/DetailsItem.mdx" /* webpackChunkName: "component---docs-components-details-item-mdx" */),
  "component---docs-components-flag-mdx": () => import("./../../../docs/components/Flag.mdx" /* webpackChunkName: "component---docs-components-flag-mdx" */),
  "component---docs-components-header-mdx": () => import("./../../../docs/components/Header.mdx" /* webpackChunkName: "component---docs-components-header-mdx" */),
  "component---docs-components-icon-mdx": () => import("./../../../docs/components/Icon.mdx" /* webpackChunkName: "component---docs-components-icon-mdx" */),
  "component---docs-components-info-box-mdx": () => import("./../../../docs/components/InfoBox.mdx" /* webpackChunkName: "component---docs-components-info-box-mdx" */),
  "component---docs-components-input-mdx": () => import("./../../../docs/components/Input.mdx" /* webpackChunkName: "component---docs-components-input-mdx" */),
  "component---docs-components-list-item-mdx": () => import("./../../../docs/components/ListItem.mdx" /* webpackChunkName: "component---docs-components-list-item-mdx" */),
  "component---docs-components-list-item-placeholder-mdx": () => import("./../../../docs/components/ListItemPlaceholder.mdx" /* webpackChunkName: "component---docs-components-list-item-placeholder-mdx" */),
  "component---docs-components-menu-box-mdx": () => import("./../../../docs/components/MenuBox.mdx" /* webpackChunkName: "component---docs-components-menu-box-mdx" */),
  "component---docs-components-post-comment-mdx": () => import("./../../../docs/components/PostComment.mdx" /* webpackChunkName: "component---docs-components-post-comment-mdx" */),
  "component---docs-components-post-comment-placeholder-mdx": () => import("./../../../docs/components/PostCommentPlaceholder.mdx" /* webpackChunkName: "component---docs-components-post-comment-placeholder-mdx" */),
  "component---docs-components-post-mdx": () => import("./../../../docs/components/Post.mdx" /* webpackChunkName: "component---docs-components-post-mdx" */),
  "component---docs-components-post-placeholder-mdx": () => import("./../../../docs/components/PostPlaceholder.mdx" /* webpackChunkName: "component---docs-components-post-placeholder-mdx" */),
  "component---docs-components-search-bar-mdx": () => import("./../../../docs/components/SearchBar.mdx" /* webpackChunkName: "component---docs-components-search-bar-mdx" */),
  "component---docs-components-social-button-mdx": () => import("./../../../docs/components/SocialButton.mdx" /* webpackChunkName: "component---docs-components-social-button-mdx" */),
  "component---docs-components-text-mdx": () => import("./../../../docs/components/Text.mdx" /* webpackChunkName: "component---docs-components-text-mdx" */),
  "component---docs-components-user-avatar-mdx": () => import("./../../../docs/components/UserAvatar.mdx" /* webpackChunkName: "component---docs-components-user-avatar-mdx" */),
  "component---docs-theaming-colors-mdx": () => import("./../../../docs/theaming/Colors.mdx" /* webpackChunkName: "component---docs-theaming-colors-mdx" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

