// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection:'row',
    height: 30,
    alignItems: 'center',
    marginLeft: 5,
  },
  content: {
    marginLeft: 5,
  }
});
