// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

import {colors} from "../../../../../Core/theme/colors";

interface Style {
  layout: ViewStyle
  container: ViewStyle
  containerActive: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  layout: {
    width: 85,
    flexDirection: 'row',
  },
  container: {
    flexDirection: 'row',
    height: 30,
    alignItems: 'center',
    paddingHorizontal: 5,
    backgroundColor: colors.grey["100"],
    borderRadius: 15,
  },
  containerActive: {
    backgroundColor: colors.primary.main,
  },
  content: {
    paddingVertical: 5,
    paddingHorizontal: 10,
  }
});
