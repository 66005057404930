// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  small: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  small: {
    width: 20,
    height: 13,
  },
});
