import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { CommentBox } from '@components/CommentBox';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  CommentBox,
  React
};