// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

import { colors } from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  avatarContainer: ViewStyle
  buttonContainer: ViewStyle
  iconContainer: ViewStyle
  containerActive: ViewStyle
  header: ViewStyle
  actionContainer: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    borderRadius: 15,
    overflow: 'hidden',
    borderColor: colors.grey["300"],
    borderWidth: 1,
    width: 98,
    height: 144,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: colors.background.white,
    marginRight: 5,
  },
  containerActive: {
    borderColor: colors.primary.main,
  },
  avatarContainer: {
    marginTop: 13,
    marginBottom: 7,
  },
  buttonContainer: {
    marginTop: 8,
    height: 20,
    width: 78,
  },
  iconContainer: {
    position: 'absolute',
    top: 5,
    right: 7,
  },
});
