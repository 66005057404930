import React, {FunctionComponent} from 'react';
// @ts-ignore
import {Text, View} from 'react-native';

import {colors} from '../theme/colors';
import styles from './styles';

export interface ColorsProps {
  name: string
}

export const Colors: FunctionComponent<ColorsProps> = ({name}) => {
  const displayColors = colors[name];
  return (
    <View style={styles.container}>
      {Object.entries(displayColors).map(([colorName, colorCode], i) => {
        return (
          <View key={i} style={{alignItems: 'center'}}>
            <Text>{colorName}</Text>
            <View style={[styles.box, {backgroundColor: colorCode,}]} />
            <Text>{colorCode}</Text>
          </View>
        )
      })}
    </View>
  );
}
