import React, {FunctionComponent} from "react";
// @ts-ignore
import {Platform} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {SeasonType} from "../../../../../Core/types/Season";
import {Icon} from "../../../../../Icon";
import {colors} from "../../../../../Core/theme/colors";

interface SeasonNameInterface {
  season: SeasonType | null
}

export const SeasonName: FunctionComponent<SeasonNameInterface> = ({season}) => {
  if (!season) {
    return null;
  }

  const iconName = Platform.OS === 'android' ? 'point-bottom' : 'point';

  return (
    <Text style={styles.contentContainer}>
      <Icon name={iconName} color={colors.grey["800"]} styleContainer={styles.iconContainerStyle} />
      <Text type="body" color={colors.grey["800"]} numberOfLines={1}>{season.name}</Text>
    </Text>
  )
}
