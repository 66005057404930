// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginTop: 2,
  },
});
