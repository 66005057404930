import React, {FunctionComponent} from 'react';
// @ts-ignore
import {Pressable, TextInputProperties, View} from 'react-native';

import {Icon} from '../Icon';
import {Text} from '../Text';

import {styles} from './styles';
import {colors} from '../Core/theme/colors';


interface InfoBoxProps extends TextInputProperties {
  title: string
  description: string
  iconName: string
  theme?: 'light' | 'dark'

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void

}

export const InfoBox: FunctionComponent<InfoBoxProps> = ({
  title,
  description,
  iconName,
  theme = 'light',
  onPress,
}: InfoBoxProps) => {
  const ContainerComponent = onPress ? Pressable : View;
  const bgContainerStyle = theme === 'light' ? styles.containerLight : styles.containerDark;

  return (
    <ContainerComponent style={[styles.container, bgContainerStyle]} onPress={onPress}>
      <Icon name={iconName} size="xLarge" />
      <View style={styles.content}>
        <Text style={styles.title}>{title}</Text>
        <Text color={colors.text.secondary}>{description}</Text>
      </View>
    </ContainerComponent>
  )
}
