import React, {ElementType, FunctionComponent, useState, useRef} from "react";
// @ts-ignore
import {ActivityIndicator, View} from 'react-native';

import {styles} from "./styles";
import {colors} from "../../../Core/theme/colors";

export const Gallery: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator color={colors.text.primary} />
    </View>
  )
}
