import React, {FunctionComponent} from 'react';
import {VenueType} from '../Core/types/Venue';
import {Avatar, AvatarProps} from './Avatar';


interface VenueAvatarProps extends AvatarProps {
  /**
   * Venue object
   */
  venue: VenueType,
}

export const VenueAvatar: FunctionComponent<VenueAvatarProps> = ({
  venue,
  ...props
}) => {
  return <Avatar uri={venue.image} {...props} icon="stadium" />
}
