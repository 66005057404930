import React, {FunctionComponent} from 'react';
// @ts-ignore
import {View} from 'react-native';

import {styles} from './styles';


interface ListItemPlaceholderProps {
  /**
   * Display border bottom
   * @default false
   */
  divider?: boolean
}

export const ListItemPlaceholder: FunctionComponent<ListItemPlaceholderProps> = ({divider = false}) => {
  const containerStyle = divider ? styles.containerWithBorder : styles.container;
  return (
    <View style={containerStyle}>
      <View style={styles.content}>
        <View style={styles.contentLeft} />
        <View style={styles.contentCenter}>
          <View style={styles.contentCenterTop} />
          <View style={styles.contentCenterBottom} />
        </View>
        <View style={styles.contentRight} />
      </View>
    </View>
  )
}
