import React, {ElementType, FunctionComponent} from "react";
// @ts-ignore
import {View, Image} from 'react-native';

import {styles} from "./styles";
import {Header} from "./components/Header";

import {PostCommentType} from "../Core/types/PostComment";
import {UserType} from "../Core/types/User";

interface PostCommentProps {
  authUser: UserType,
  comment: PostCommentType
  ImageComponent?: ElementType,
  onPressUser?: () => void
  onPressOptions?: () => void
}

export const PostComment: FunctionComponent<PostCommentProps> = ({
  comment,
  authUser,
  ImageComponent = Image,
  onPressUser,
  onPressOptions,
}) => {
  const {user, message, deletedAt} = comment;

  if(deletedAt) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Header
          ImageComponent={ImageComponent}
          authUser={authUser}
          user={user}
          message={message}
          onPressOptions={onPressOptions}
          onPressUser={onPressUser}
        />
      </View>
    </View>
  )
}
