import React, {ElementType, FunctionComponent} from "react";
// @ts-ignore
import {Image, View, Pressable} from 'react-native';

import {Text} from '../../../Text'
import {TeamAvatar} from "../../../Avatar";

import {Goals} from './components/Goals';
import {Competition} from './components/Competition';

import {styles} from './styles'
import {FixtureType} from "../../../Core/types/Fixture";

interface FixtureInterface {
  fixture: FixtureType | null
  ImageComponent?: ElementType
  onPressFixture?: () => void
}

export const Fixture: FunctionComponent<FixtureInterface> = ({
  fixture,
  ImageComponent = Image,
  onPressFixture,
}) => {
  if (!fixture) {
    return null;
  }
  const {
    homeTeam,
    awayTeam,
    homeTeamGoals,
    awayTeamGoals,
    competition,
    season,
    stage,
  } = fixture;

  const ContainerComponent = onPressFixture ? Pressable : View;

  return (
    <ContainerComponent style={styles.container} onPress={onPressFixture}>
      <View style={styles.game}>
        <TeamAvatar ImageComponent={ImageComponent} team={homeTeam} size="xSmall" rounded />
        <View style={styles.homeTeam}>
          <Text type="title" numberOfLines={1}>{homeTeam.name}</Text>
        </View>
        <View style={styles.status}>
          <Goals homeTeamGoals={homeTeamGoals} awayTeamGoals={awayTeamGoals} />
        </View>
        <View style={styles.awayTeam}>
          <Text type="title" numberOfLines={1}>{awayTeam.name}</Text>
        </View>
        <TeamAvatar ImageComponent={ImageComponent} team={awayTeam} size="xSmall" rounded />
      </View>
      <Competition competition={competition} season={season} stage={stage} />
    </ContainerComponent>
  );
}
