import React from 'react';
// @ts-ignore
import {View} from 'react-native';

import RenderPoints from './components/RenderPoints';
import {styles} from './styles';

interface GalleryInterface {
  index: Number
  total: Number,
}


export const Pagination = ({currentPage, total}) => {
  if (total === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <RenderPoints currentPage={currentPage} total={total} />
      </View>
    </View>
  );
}
