import React, { ElementType, FunctionComponent } from "react";
// @ts-ignore
import { TouchableOpacity, View } from "react-native";

import { styles } from "./styles";

function containerBorder(size, rounded) {
  let borderStyle = styles.borderRadiusDefault;
  if (rounded) {
    borderStyle = styles[`${size}BorderRadius`];
  }
  return borderStyle;
}

interface ImageAvatarProps {
  /**
   * Image source
   */
  uri: string;

  /**
   * Avatar size.
   * - small: 20 x 20
   * - medium: 40 x 40
   * - large: 96 x 96
   *
   * @default medium
   */
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

  /**
   * Makes the avatar circular
   * - true
   * - false
   *
   * @default false
   * */
  rounded: boolean;

  /**
   * Custom ImageComponent for Avatar
   */
  ImageComponent: ElementType;

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void;
}

export const ImageAvatar: FunctionComponent<ImageAvatarProps> = ({
  uri,
  size = "medium",
  rounded = false,
  onPress,
  ImageComponent,
}) => {
  const ContainerComponent = onPress ? TouchableOpacity : View;
  return (
    <ContainerComponent
      onPress={onPress}
      style={[
        styles.container,
        styles[size],
        containerBorder(size, rounded),
        styles.withImage,
      ]}
    >
      <ImageComponent
        source={{ uri }}
        style={[styles.container, styles[size], containerBorder(size, rounded)]}
      />
    </ContainerComponent>
  );
};
