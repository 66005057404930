import React, {ElementType, FunctionComponent, ReactNode} from 'react';
// @ts-ignore
import {Pressable, Image, ImageBackground, SafeAreaView, View} from 'react-native';
import {Text} from '../Text';
import {Icon} from "../Icon";

import {styles} from './styles';

const arrowLeft = require('./assets/arrow-left.png');

interface HeaderProps {
  title: string
  avatar: ReactNode
  coverUri: string
  ImageComponent?: ElementType,
  onPressBackButton?: () => void
}

export const Header: FunctionComponent<HeaderProps> = ({
  title,
  avatar,
  coverUri,
  ImageComponent = ImageBackground,
  onPressBackButton,
}) => {
  return (
    <View style={styles.container}>
      <ImageComponent style={styles.imageCover} source={coverUri}>
        {onPressBackButton && (
          <SafeAreaView>
            <Pressable style={styles.arrowBackContainer}
                       onPress={onPressBackButton}>
              <Image source={arrowLeft} style={styles.arrowBackImage} />
            </Pressable>
          </SafeAreaView>
        )}
      </ImageComponent>
      <View style={styles.imageProfileContainer}>
        {avatar}
      </View>
      <View style={styles.titleContainer}>
        <Text type="th1">{title}</Text>
      </View>
    </View>
  )
}
