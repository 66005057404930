// @ts-ignore
import {StyleSheet, ViewStyle, StyleView} from 'react-native';

import {colors} from '../../../../../../../Core/theme/colors';

interface Style {
  iconContainer: ViewStyle
  smallIcon: StyleView
  largeIcon: StyleView
}

export const styles: Style = StyleSheet.create<Style>({
  smallIcon: {
    fontSize: 6,
  },
  largeIcon: {
    fontSize: 10,
  },
  iconContainer: {
    padding: 2.5,
  },
});
