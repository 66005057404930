import React, {FunctionComponent} from 'react';
// @ts-ignore
import {View} from 'react-native';

import {styles} from './styles';
import {Text} from '../../../Text';

interface SubtitleProps {
  subtitle?: string
}

export const Subtitle: FunctionComponent<SubtitleProps> = ({
  subtitle
}: SubtitleProps) => {
  if (!subtitle) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text type="tH2" numberOfLines={1}>{subtitle}</Text>
    </View>
  );
}
