import React, {FunctionComponent, ReactElement, ElementType} from 'react';
// @ts-ignore
import {Pressable, TextInputProperties, View} from 'react-native';

import {styles} from './styles';
import {colors} from '../Core/theme/colors';
import {Icon} from '../Icon';
import {Rank} from './components/Rank';
import {UserType} from "../Core/types/User";
import {UserAvatar} from "../Avatar";
import {Title} from "./components/Title";
import {Subtitle} from "./components/Subtitle";
import {StandingValues} from "./components/StandingValues";


interface ListItemProps extends TextInputProperties {
  title?: string
  subtitle?: string

  /**
   * Display border bottom
   * @default false
   */
  divider?: boolean

  /**
   * Display position
   */
  position?: number

  /**
   * Display Arrow right
   */
  chevron?: boolean

  icon?: string

  color?: string

  highlight?: boolean

  /**
   * User details
   */
  user?: UserType

  /**
   * User details
   */
  actionButton?: ReactElement

  /**
   * Standing values. Array of numbers
   */
  standingValues?: number[]

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void

  /**
   * Callback function when pressing component. (optional)
   */
  onPressUser?: () => void

  onPressRightContent?: () => void
  /**
   * Custom ImageComponent for Avatar
   */
  ImageComponent?: ElementType;

}

export const ListItem: FunctionComponent<ListItemProps> = ({
  title,
  subtitle,
  divider = false,
  chevron = false,
  highlight = false,
  icon,
  color,
  user,
  position,
  actionButton,
  standingValues = [],
  onPress,
  onPressUser,
  onPressRightContent,
  ImageComponent,
}: ListItemProps) => {
  const ContainerComponent = onPress ? Pressable : View;
  const ContentRightComponent = onPressRightContent ? Pressable : View;
  const containerStyleDivider = divider ? styles.containerWithBorder : {};
  const containerStyleHighlight = highlight ? styles.containerHighlight : {};
  const contentRightStyle = actionButton ? styles.contentRightAction : styles.contentRight;

  return (
    <View style={[styles.container, containerStyleDivider, containerStyleHighlight]}>
      <View style={styles.content}>
        <Rank position={position} />
        <View>
          {user && <UserAvatar user={user} rounded size="medium" ImageComponent={ImageComponent} onPress={onPressUser} />}
          {icon && <Icon name={icon} color={color} />}
        </View>
        <ContainerComponent style={styles.contentCenter} onPress={onPress}>
          <Title title={title} color={color} />
          <Subtitle subtitle={subtitle} />
        </ContainerComponent>
        <ContentRightComponent style={contentRightStyle} onPress={onPressRightContent}>
          {actionButton}
          <StandingValues values={standingValues} />
          {chevron && <Icon name="chevron-right" color={colors.text.secondary} size="small" />}
        </ContentRightComponent>
      </View>
    </View>
  )
}
