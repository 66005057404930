import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import {
  Image,
  View,
  TextInput,
  NativeSyntheticEvent,
  TextInputContentSizeChangeEventData,
  Platform,
} from "react-native";
import { UserAvatar } from "../Avatar";
import { Button } from "../Button";

import { styles } from "./styles";
import { colors } from "../Core/theme/colors";
import { UserType } from "../Core/types/User";

interface CommentBoxProps {
  /**
   * To display the user avatar
   */
  authUser: UserType;

  /**
   * Callback function when typing in text input
   */
  onChangeText?: (message: string) => void;

  /**
   * Callback function when Send button is pressed
   */
  onSubmit?: () => void;

  /**
   * Value for the text input
   */
  value: string;

  /**
   * Disables the button component
   * @default true
   */
  disabled?: boolean;

  /**
   * To display the loading indicator on the button component
   * @default false
   */
  isSending?: boolean;

  /**
   * To autofocus on the textinput component
   */
  autoFocus?: boolean;

  /**
   * Height of the textinput component
   */
  height?: number;

  /**
   * To set the height of the textinput component
   */
  setHeight?: Dispatch<SetStateAction<number>>;
}

export const CommentBox: FunctionComponent<CommentBoxProps> = ({
  authUser,
  onChangeText,
  value,
  disabled = true,
  isSending = false,
  onSubmit,
  autoFocus,
  height,
  setHeight,
}) => {
  const input = useRef<TextInput>(null);

  if (!height || !setHeight) {
    [height, setHeight] = useState(0);
  }

  useEffect(() => {
    if (autoFocus && input.current) {
      Platform.OS === "ios"
        ? input.current.focus()
        : setTimeout(() => {
            input.current.focus();
          }, 40);
    }
  }, []);

  const handleInputHeight = (
    e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>
  ) => setHeight(e.nativeEvent.contentSize.height);

  return (
    <View style={styles.container}>
      <View style={styles.avatar}>
        <UserAvatar
          user={authUser}
          size="large"
          rounded
          ImageComponent={Image}
        />
      </View>
      <TextInput
        ref={input}
        editable={!isSending}
        multiline
        textAlignVertical="top"
        onChangeText={onChangeText}
        placeholder="Add a comment..."
        placeholderTextColor={colors.text.secondary}
        underlineColorAndroid="transparent"
        value={value}
        style={
          !value
            ? [styles.input, styles.placeholder]
            : [styles.input, { height: Math.min(Math.max(40, height), 300) }]
        }
        onContentSizeChange={handleInputHeight}
      />
      <Button
        title="Send"
        disabled={disabled}
        buttonStyle={styles.button}
        loading={isSending}
        loadingStyle={styles.loading}
        onPress={onSubmit}
      />
    </View>
  );
};
