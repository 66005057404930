import React, {FunctionComponent} from 'react';
// @ts-ignore
import {Pressable, TextInputProperties, View} from 'react-native';

import {Icon} from '../Icon';
import {Text} from '../Text';

import {styles} from './styles';
import {colors} from '../Core/theme/colors';


interface MenuBoxProps extends TextInputProperties {
  title: string
  iconName: string
  subtitle?: string

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void

}

export const MenuBox: FunctionComponent<MenuBoxProps> = ({
  title,
  iconName,
  subtitle,
  onPress,
}: MenuBoxProps) => {
  const ContainerComponent = onPress ? Pressable : View;
  const color = onPress ? colors.text.primary :  colors.text.secondary;

  return (
    <ContainerComponent style={styles.item} onPress={onPress}>
      <Icon name={iconName} size="xLarge" color={color} />
      <Text style={styles.title} color={color}>{title}</Text>
      {subtitle && (<Text style={styles.title} color={color}>{subtitle}</Text>)}
    </ContainerComponent>
  )
}
