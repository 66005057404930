import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostComment } from '@components/PostComment';
import { comment1, comment2, comment3 } from "../../../../../docs/components/data/postComments";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  PostComment,
  comment1,
  comment2,
  comment3,
  React
};