import React, {ElementType, FunctionComponent} from "react";
// @ts-ignore
import {View, Image} from 'react-native';

import {styles} from "./styles";
import {Header} from "./components/Header";
import {Gallery} from "./components/Gallery";
import {Message} from "./components/Message";
import {Footer} from "./components/Footer";
import {Fixture} from "./components/Fixture";

import {PostType} from "../Core/types/Post";

interface PostProps {
  post: PostType
  ImageComponent?: ElementType,
  onPressUser?: () => void
  onPressVenue?: () => void
  onPressFixture?: () => void
  onPressOptions?: () => void
  onPressReaction?: () => void
  onPressReactionCount?: () => void
  onPressComments?: () => void
  onPressGallery?: () => void
}

export const Post: FunctionComponent<PostProps> = ({
  post,
  ImageComponent= Image,
  onPressUser,
  onPressVenue,
  onPressFixture,
  onPressOptions,
  onPressReaction,
  onPressReactionCount,
  onPressComments,
  onPressGallery,
}) => {
  const {user, venue, fixture, message, images, viewerReaction, reactionsCount, commentsCount} = post;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Header
          ImageComponent={ImageComponent}
          user={user}
          venue={venue}
          onPressOptions={onPressOptions}
          onPressUser={onPressUser}
          onPressVenue={onPressVenue}
        />
        <Fixture fixture={fixture} onPressFixture={onPressFixture} ImageComponent={ImageComponent} />
        <Gallery ImageComponent={ImageComponent} images={images} />
        <Message message={message} />
        <Footer
          viewerReaction={viewerReaction}
          reactionsCount={reactionsCount}
          commentsCount={commentsCount}
          onPressReaction={onPressReaction}
          onPressReactionCount={onPressReactionCount}
          onPressComments={onPressComments}
        />
      </View>
    </View>
  )
}
