import React, {FunctionComponent, useEffect, useState} from "react";
// @ts-ignore
import {Pressable, View} from 'react-native';

import {styles} from "./styles";
import {colors} from "../../../../../Core/theme/colors";
import {ReactionType} from "../../../../../Core/types/Reaction";
import {Text} from "../../../../../Text";
import {Icon} from "../../../../../Icon";

interface ReactionsInterface {
  reactionsCount?: number
  onPressReaction?: () => void
  onPressReactionCount?: () => void
  viewerReaction?: ReactionType[]
}

function ReactionCount({count, color, onPressReactionCount}) {
  if (!count) {
    return null;
  }
  const ContainerComponent = onPressReactionCount ? Pressable : View;
  return (
    <ContainerComponent style={styles.content} onPress={onPressReactionCount}>
      <Text type="titleFooter" color={color}>{count}</Text>
    </ContainerComponent>
  )

}

const initialState = {
  active: false,
  count: 0,
}

export const Reactions: FunctionComponent<ReactionsInterface> = ({
  reactionsCount = 0,
  viewerReaction,
  onPressReaction,
  onPressReactionCount,
}) => {
  const [state, setState] = useState({...initialState, count: reactionsCount});

  useEffect(() => {
    const newState = !!(viewerReaction && viewerReaction.length > 0);
    if (newState !== state.active) {
      setState({...state, active: newState});
    }
  }, [viewerReaction])

  const color = state.active ? colors.text.white : colors.text.primary;

  const ContainerComponent = onPressReaction ? Pressable : View;

  const viewerReactionStyle = state.active ? styles.containerActive : {};
  const containerStyle = {...styles.container, ...viewerReactionStyle};

  const handleReact = () => {
    if (onPressReaction) {
      const newState = {...state};
      newState.active = !newState.active;
      newState.count = state.active ? state.count - 1 : state.count + 1;
      if (newState.count < 0) {
        newState.count = 0;
      }
      setState(newState);
      onPressReaction();
    }
  }

  return (
    <View style={styles.layout}>
      <View style={containerStyle}>
        <ContainerComponent onPress={handleReact}>
          <Icon name="reaction-ole" color={color} />
        </ContainerComponent>
        <ReactionCount count={state.count} color={color} onPressReactionCount={onPressReactionCount} />
      </View>
    </View>
  )
}
