import React, {ElementType, FunctionComponent} from "react";
// @ts-ignore
import {Image, View, Pressable} from 'react-native';
import {UserAvatar} from "../../../Avatar";
import {Text} from "../../../Text";
import {Icon} from "../../../Icon";

import {Venue} from "./components/Venue";

import {styles} from "./styles";
import {UserType} from "../../../Core/types/User";
import {colors} from "../../../Core/theme/colors";
import {VenueType} from "../../../Core/types/Venue";

interface HeaderProps {
  user: UserType
  ImageComponent?: ElementType,
  venue: VenueType | null
  onPressUser?: () => void
  onPressVenue?: () => void
  onPressOptions?: () => void
}

export const Header: FunctionComponent<HeaderProps> = ({
  user,
  venue,
                                                         ImageComponent= Image,
  onPressUser,
  onPressVenue,
  onPressOptions,
}) => {
  const UserContainerComponent = onPressUser ? Pressable : View;
  const VenueContainerComponent = onPressVenue ? Pressable : View;
  const OptionsContainerComponent = onPressOptions ? Pressable : View;

  return (
    <View style={styles.container}>
      <UserContainerComponent onPress={onPressUser}>
        <UserAvatar user={user} size="large" rounded ImageComponent={ImageComponent} />
      </UserContainerComponent>
      <View style={styles.content}>
        <UserContainerComponent onPress={onPressUser}>
          <Text type="title" numberOfLines={1}>{user.name}</Text>
        </UserContainerComponent>
        <VenueContainerComponent onPress={onPressVenue}>
          <Venue venue={venue} />
        </VenueContainerComponent>
      </View>
      <OptionsContainerComponent onPress={onPressOptions}>
        <Icon name="more" color={colors.text.secondary} />
      </OptionsContainerComponent>
    </View>
  )
}
