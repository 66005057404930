import React, {ElementType, FunctionComponent} from 'react';
import {View} from 'react-native';

import {UserType} from '../Core/types/User';
import {Avatar, AvatarProps} from './Avatar';
import {userIconName} from "../../utils";
import {styles} from './styles';

interface UsersAvatarsProps extends AvatarProps {
  /**
   * Array of User object
   */
  users: UserType[],
}

export const UsersAvatars: FunctionComponent<UsersAvatarsProps> = ({
  users,
  ...props
}) => {
  const avatars = users.slice(0, 3).map((user, index) =>  {
    return (

    <View style={styles[`avatar${index}`]} key={user.id}>
      <Avatar
        uri={user?.profilePicture}
        icon={userIconName(user)}
        {...props} />
    </View>
  )});

  return (
    <View style={styles.avatarsContainer}>
      {avatars}
    </View>
  )
}
