// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';
import { colors } from '../Core/theme/colors';

interface Style {
  facebookButton: ViewStyle
  googleButton: ViewStyle
  appleButton: ViewStyle
  envelopeButton: ViewStyle
  facebookTitle: TextStyle
  googleTitle: TextStyle
  appleTitle: TextStyle
  envelopeTitle: TextStyle
  icon: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  facebookButton: {
    backgroundColor: colors.social.facebook,
    justifyContent: 'flex-start',
  },
  googleButton: {
    backgroundColor: colors.social.google,
    justifyContent: 'flex-start',
  },
  appleButton: {
    justifyContent: 'flex-start',
    borderColor: colors.text.primary,
  },
  envelopeButton: {
    justifyContent: 'flex-start',
    borderColor: colors.text.primary,
  },
  facebookTitle: {
  },
  googleTitle: {
  },
  appleTitle: {
    color: colors.text.primary,
  },
  envelopeTitle: {
    color: colors.text.primary,
  },
  icon: {
    marginRight: 7,
  }
});
