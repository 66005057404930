import React, {FunctionComponent} from "react";
// @ts-ignore
import {View, Pressable} from 'react-native';

import {Text} from "../../../../../Text";
import {Icon} from "../../../../../Icon";

import {styles} from "./styles";

interface CommentsInterface {
  commentsCount?: number
  onPressComments?: () => void
}


function CommentCount({count}) {
  if (!count) {
    return null
  }
  return (
    <View style={styles.content}>
      <Text type="titleFooter">{count}</Text>
    </View>
  )
}

export const Comments: FunctionComponent<CommentsInterface> = ({
  commentsCount,
  onPressComments,
}) => {
  const ContainerComponent = onPressComments ? Pressable : View;
  return (
    <ContainerComponent style={styles.container} onPress={onPressComments}>
      <Icon name="comment" />
      <CommentCount count={commentsCount} />
    </ContainerComponent>
  )
}
