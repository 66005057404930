import React, {FunctionComponent} from 'react';
import {CompetitionType} from '../Core/types/Competition';
import {Avatar, AvatarProps} from './Avatar';


interface CompetitionAvatarProps extends AvatarProps {
  /**
   * Competition object
   */
  competition: CompetitionType,
}

export const CompetitionAvatar: FunctionComponent<CompetitionAvatarProps> = ({
  competition,
  ...props
}) => {
  return <Avatar uri={competition.logo} {...props} icon="cup" />
}
