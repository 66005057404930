import React, {FunctionComponent, ReactElement} from 'react';
// @ts-ignore
import {Pressable, View} from 'react-native';

import {Text} from '../Text';
import {styles} from './styles';

import {colors} from '../Core/theme/colors';



interface DetailsItemProps {
  title: string
  value?: string | number
  rightElement?: ReactElement
  onPress?: () => void
}

export const DetailsItem: FunctionComponent<DetailsItemProps> = ({
  title,
  value = '',
  rightElement,
  onPress,
}) => {
  const Component = onPress ? Pressable : View;
  return (
    <View style={styles.container}>
      <View style={styles.contentTitle}>
        <Text type="th8" color={colors.text.secondary}>{title}</Text>
      </View>
      <Component style={styles.contentValue} onPress={onPress}>
        <Text type="th8" color={colors.text.primary}>{value}</Text>
      </Component>
      <View style={styles.contentRight}>
        {rightElement}
      </View>
    </View>
  )
}
