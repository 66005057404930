// @ts-ignore
import {Dimensions, StyleSheet, ViewStyle} from 'react-native';

export const imageWidth = Dimensions.get("window").width - 70;

interface Style {
  image: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  image: {
    marginHorizontal: 20,
    width: imageWidth,
    height: 225,
    borderRadius: 15,
  },
});
