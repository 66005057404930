export const statusPost1 = {
  user: {
    name: 'Marcel Popescu',
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id.',
}

export const statusPost2 = {
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 11,
  commentsCount: 4,
  viewerReaction: {
    name: 'Ole',
  },
  images: [
    {
      id: 'id1',
      uri: 'https://picsum.photos/400',
    },
    {
      id: 'id2',
      uri: 'https://picsum.photos/400',
    },
    {
      id: 'id3',
      uri: 'https://picsum.photos/400',
    },
  ],
}

export const statusPost3 = {
  user: {
    name: 'Marcel Popescu',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 3,
}

export const statusPost4 = {
  user: {
    name: 'Joy',
    gender: {
      name: 'female'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat.',
  commentsCount: 12,
}

export const stadiumPost1 = {
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 11,
  commentsCount: 4,
  viewerReaction: {
    name: 'Ole',
  },
  venue: {
    name: 'Camp Nou',
    city: {
      name: 'Barcelona',
    }
  }
}


export const stadiumPost2 = {
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 11,
  commentsCount: 4,
  viewerReaction: {
    name: 'Ole',
  },
  venue: {
    name: 'Camp Nou',
  }
}

export const fixturePost1 = {
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 12,
  commentsCount: 43,
  viewerReaction: {
    name: 'Ole',
  },
  venue: {
    name: 'Santiago Bernabeu',
    city: {
      name: 'Madrid',
    },
  },
  fixture: {
    homeTeam: {
      name: 'Real Madrid',
      logo: 'https://cdn.thefansarena.com/images/team/e5c14228-6f8d-4b22-b1fe-75699ec09d24.png',
    },
    awayTeam: {
      name: 'Barcelona',
      logo: 'https://cdn.thefansarena.com/images/team/fffd9aae-ac3e-42ee-a1f9-868c6438012a.png',
    },
    homeTeamGoals: 2,
    awayTeamGoals: 4,
  }
}

export const fixturePost2 = {
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
  reactionsCount: 12,
  commentsCount: 43,
  viewerReaction: {
    name: 'Ole',
  },
  venue: {
    name: 'Santiago Bernabeu',
    city: {
      name: 'Madrid',
      country: {
        name: 'Spain',
      }
    },
  },
  fixture: {
    homeTeam: {
      name: 'Real Madrid',
      logo: 'https://cdn.thefansarena.com/images/team/e5c14228-6f8d-4b22-b1fe-75699ec09d24.png',
    },
    awayTeam: {
      name: 'Barcelona',
      logo: 'https://cdn.thefansarena.com/images/team/fffd9aae-ac3e-42ee-a1f9-868c6438012a.png',
    },
    homeTeamGoals: 0,
    awayTeamGoals: 0,
    competition: {
      name: 'La Liga',
    },
    season: {
      name: '2018/2019',
    },
    stage: {
      name: 'Regular Season - 3',
    },
  },
  images: [
    {
      id: 'id1',
      uri: 'https://picsum.photos/400',
    }
  ],
}

