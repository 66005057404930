// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

import {colors} from "../../../Core/theme/colors";

interface Style {
  container: ViewStyle
  game: ViewStyle
  homeTeam: ViewStyle
  awayTeam: ViewStyle
  status: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginHorizontal: 20,
    marginBottom: 20,
    borderRadius: 15,
    flex: 1,
    overflow: 'hidden',
  },
  game: {
    backgroundColor: colors.grey["150"],
    height: 60,
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  homeTeam: {
    width: '33%',
  },
  awayTeam: {
    width: '33%',
    alignItems: 'flex-end',
  },
  status: {
    alignItems: 'center',
  },
});
