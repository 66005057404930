import React, { FunctionComponent } from "react";
// @ts-ignore
import { TouchableOpacity, View } from "react-native";

import { Icon } from "../Icon";
import { colors } from "../Core/theme/colors";
import { styles } from "./styles";

function containerBorder(size, rounded) {
  let borderStyle = styles.borderRadiusDefault;
  if (rounded) {
    borderStyle = styles[`${size}BorderRadius`];
  }
  return borderStyle;
}

interface IconAvatarProps {
  /**
   * Icon name
   *
   * */
  name: string

  /**
   * Avatar size.
   * - small: 20 x 20
   * - medium: 40 x 40
   * - large: 96 x 96
   *
   * @default medium
   */
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

  /**
   * Makes the avatar circular
   * - true
   * - false
   *
   * @default false
   * */
  rounded: boolean;

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void;
}

export const IconAvatar: FunctionComponent<IconAvatarProps> = ({
  name,
  size = "medium",
  rounded = false,
  onPress,
}) => {
  const ContainerComponent = onPress ? TouchableOpacity : View;
  return (
    <ContainerComponent
      onPress={onPress}
      style={[styles.container, styles[size], containerBorder(size, rounded)]}
    >
      <Icon name={name} size={size} color={colors.text.secondary} />
    </ContainerComponent>
  );
};
