import React, {Fragment, memo} from "react";

import {Icon} from '../../../../../../../Icon';
import {colors} from '../../../../../../../Core/theme/colors';
import {styles} from './styles';

function RenderPoints({currentPage, total}) {
  const pointsArray = Array.from(Array(total).keys())
  return (
    <Fragment>
      {
        pointsArray.map((index) => {
          const current = currentPage === index;
          const iconStyle = current ? styles.largeIcon : styles.smallIcon;

          return <Icon
            name="point-full"
            color={current ? colors.primary.main : colors.grey["500"]}
            key={`index${index}`}
            style={iconStyle}
            styleContainer={styles.iconContainer}
          />
        })}

    </Fragment>
  )
}

export default memo(RenderPoints);
