// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

const iconSizes = {
  xxLarge: 48,
  xLarge: 32,
  large: 16,
  medium: 10,
  small: 7,
  xSmall: 4,
};

interface Style {
  xSmall: TextStyle
  small: TextStyle
  medium: TextStyle
  large: TextStyle
  xLarge: TextStyle
  xxLarge: TextStyle
  container: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  xSmall: {
    fontSize: iconSizes.xSmall,
  },
  small: {
    fontSize: iconSizes.small,
  },
  medium: {
    fontSize: iconSizes.medium,
  },
  large: {
    fontSize: iconSizes.large,
  },
  xLarge: {
    fontSize: iconSizes.xLarge,
  },
  xxLarge: {
    fontSize: iconSizes.xxLarge,
  },
  container: {
    padding: 5,
  }
});
