import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Post } from '@components/Post';
import { statusPost1, statusPost2, statusPost3, statusPost4, stadiumPost1, stadiumPost2, fixturePost1, fixturePost2 } from "../../../../../docs/components/data/posts.tsx";
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Post,
  statusPost1,
  statusPost2,
  statusPost3,
  statusPost4,
  stadiumPost1,
  stadiumPost2,
  fixturePost1,
  fixturePost2,
  React
};