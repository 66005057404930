// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  contentContainer: ViewStyle
  venueContainer: ViewStyle
  iconContainer: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 3,
    overflow: 'hidden',
    flexWrap: 'nowrap',
  },
  venueContainer: {
    flexGrow: 0,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  iconContainer: {
    padding: 0,
  }
});
