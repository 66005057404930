// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import { colors } from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  containerWithBorder: ViewStyle
  containerHighlight: ViewStyle
  content: ViewStyle
  contentCenter: ViewStyle
  contentRight: ViewStyle
  contentRightAction: ViewStyle
  chevron: TextStyle
}

export const styles: Style = StyleSheet.create<Style>({
  containerHighlight: {
    backgroundColor: colors.primary.light,
  },
  container: {
    backgroundColor: colors.background.white,
  },
  containerWithBorder: {
    borderColor: colors.grey["200"],
    borderBottomWidth: 1,
  },
  content: {
    height: 54,
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 15,
  },
  contentCenter: {
    flex: 1,
    paddingLeft: 10,
  },
  contentRight: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
  },
  contentRightAction: {
    width: 82,
  },
});
