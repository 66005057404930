export const fonts = {
  regular: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: 12,
  },
  black: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontStyle: 'black',
    fontSize: 12,
  },
  italic: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontSize: 12,
  },
};
