import React, {ElementType, FunctionComponent, useState, useRef} from "react";
// @ts-ignore
import {FlatList, View} from 'react-native';

import {ImageType} from "../../../Core/types/Image";

import {ImageItem} from "./components/ImageItem";
import {Pagination} from './components/Pagination';

import {imageWidth} from "./components/ImageItem/styles";
import {styles} from "./styles";

interface GalleryInterface {
  images: ImageType[]
  ImageComponent: ElementType,
}

export const Gallery: FunctionComponent<GalleryInterface> = ({images, ImageComponent}) => {
  const [currentPage, serCurrentPage] = useState(0);
  const flatList = useRef(null);

  if (!images) {
    return null;
  }

  const imagesCount = images.length;
  if(imagesCount === 0) {
    return null;
  }

  const renderItem = ({item}) => {
    return <ImageItem image={item} ImageComponent={ImageComponent} />
  }

  const onScrollEnd = ({nativeEvent}) => {
    const {contentOffset, layoutMeasurement} = nativeEvent;
    const pageNum = Math.floor(parseInt(contentOffset.x, 10) / parseInt(layoutMeasurement.width, 10));
    if (pageNum !== currentPage) {
      serCurrentPage(pageNum);
    }
  }

  const getItemLayout = (data, position) => {
    return {
      length: imageWidth,
      offset: imageWidth * position,
      index: position
    };
  }

  /* if is only one image, we will return an image not a list */
  if (imagesCount === 1) {
    return (
      <View style={styles.container}>
        <ImageItem image={images[0]} ImageComponent={ImageComponent} />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <FlatList
        getItemLayout={getItemLayout}
        style={styles.list}
        data={images}
        // extraData={currentPage}
        horizontal
        ref={flatList}
        pagingEnabled
        onMomentumScrollEnd={onScrollEnd}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      />
      <Pagination currentPage={currentPage} total={imagesCount} />
    </View>
  )
}
