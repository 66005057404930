import React, {FunctionComponent} from 'react';
import {UserType} from '../Core/types/User';
import {Avatar, AvatarProps} from './Avatar';
import {userIconName} from "../../utils";

interface UserAvatarProps extends AvatarProps {
  /**
   * User object
   */
  user: UserType,
}

export const UserAvatar: FunctionComponent<UserAvatarProps> = ({
  user,
  ...props
}) => {
  return <Avatar uri={user?.profilePicture} icon={userIconName(user)} {...props} />
}
