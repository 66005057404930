// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  imageCover: ViewStyle
  imageProfileContainer: ViewStyle
  imageProfile: ViewStyle
  titleContainer: ViewStyle
  arrowBackContainer: ViewStyle
  arrowBackImage: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    width: '100%',
    // height: 312,
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imageCover: {
    width: '100%',
    height: 230,
  },
  imageProfileContainer: {
    borderRadius: 15,
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: 'white',
    padding: 1,
    top: 154,
  },
  titleContainer: {
    paddingTop: 37,
  },
  arrowBackContainer: {
    marginTop: 30,
    marginLeft: 10,
    width: 30,
    paddingTop: 5,
  },
  arrowBackImage: {
    width: 16,
    height: 32,
    alignSelf: 'center',
  },
});
