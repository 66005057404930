import React, {FunctionComponent} from 'react';

import {styles} from './styles';
import {Button, ButtonProps} from '../Button';
import {Icon} from '../Icon';
import {colors} from '../Core/theme/colors';

interface SocialButtonProps extends ButtonProps {
  kind: 'facebook' | 'google' | 'apple' | 'envelope'
}

const titleMap = new Map();
titleMap.set('facebook', 'Continue with Facebook');
titleMap.set('google', 'Continue with Google');
titleMap.set('apple', 'Continue with Apple');
titleMap.set('envelope', 'Continue with Email');

function getTitle(title: string, kind: string): string {
  let defaultTitle = title;
  if(!defaultTitle && titleMap.has(kind)) {
    defaultTitle = titleMap.get(kind);
  }
  return defaultTitle;
}

export const SocialButton: FunctionComponent<SocialButtonProps> = ({kind, ...props}) => {
  const type = ['apple', 'envelope'].includes(kind) ? 'outline' : 'solid';
  const iconColor = ['facebook', 'google'].includes(kind) ? 'white' : colors.text.primary;
  const activityIndicatorColor = ['facebook', 'google'].includes(kind) ? 'white' : colors.text.primary;
  const buttonStyle = styles[`${kind}Button`];
  const titleStyle = styles[`${kind}Title`];
  const {title} = props;
  return (
    <Button
      {...props}
      icon={<Icon name={kind} color={iconColor} style={styles.icon} />}
      type={type}
      buttonStyle={buttonStyle}
      titleStyle={titleStyle}
      title={getTitle(title, kind)}
      activityIndicatorColor={activityIndicatorColor}
    />
  );
};
