import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";
import {Header} from "./components/Header";

export const PostCommentPlaceholder: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Header />
      </View>
    </View>
  )
}
