// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';
import {colors} from "../../../Core/theme/colors";

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    paddingTop: 10,
  },
  content: {
    height: 20,
    borderRadius: 1,
    borderColor: colors.grey["500"],
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'dashed',
    backgroundColor: colors.grey["100"],
  },
});
