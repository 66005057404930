// @ts-ignore
import { StyleSheet, ViewStyle } from "react-native";

import { colors } from "../Core/theme/colors";

const avatarSizes = {
  xxLarge: 96,
  xLarge: 48,
  large: 40,
  medium: 36,
  small: 24,
  xSmall: 20,
};
// * xs s m l xl xxl
interface Style {
  container: ViewStyle;
  small: ViewStyle;
  medium: ViewStyle;
  large: ViewStyle;
  borderRadiusNone: ViewStyle;
  borderRadiusDefault: ViewStyle;
  smallBorderRadius: ViewStyle;
  mediumBorderRadius: ViewStyle;
  largeBorderRadius: ViewStyle;
  withImage: ViewStyle;
  avatarsContainer: ViewStyle;
  avatarsContent: ViewStyle;
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    backgroundColor: colors.grey["100"],
    borderRadius: 3,
    width: avatarSizes.medium,
    height: avatarSizes.medium,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  xSmall: {
    width: avatarSizes.xSmall,
    height: avatarSizes.xSmall,
  },
  small: {
    width: avatarSizes.small,
    height: avatarSizes.small,
  },
  medium: {
    width: avatarSizes.medium,
    height: avatarSizes.medium,
  },
  large: {
    width: avatarSizes.large,
    height: avatarSizes.large,
  },
  xLarge: {
    width: avatarSizes.xLarge,
    height: avatarSizes.xLarge,
  },
  xxLarge: {
    width: avatarSizes.xxLarge,
    height: avatarSizes.xxLarge,
  },
  borderRadiusNone: {
    borderRadius: 0,
  },
  borderRadiusDefault: {
    borderRadius: 3,
  },
  smallBorderRadius: {
    borderRadius: avatarSizes.small / 2,
  },
  mediumBorderRadius: {
    borderRadius: avatarSizes.medium / 2,
  },
  largeBorderRadius: {
    borderRadius: avatarSizes.large / 2,
  },
  xLargeBorderRadius: {
    borderRadius: 15,
  },
  xxLargeBorderRadius: {
    borderRadius: 15,
  },
  withImage: {
    backgroundColor: "transparent",
  },
  avatarsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar0: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 15,
  },
  avatar1: {
    position: 'absolute',
    right: 15,
    zIndex: 2,
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: 15,
  },
  avatar2: {
    position: 'absolute',
    right: 30,
    zIndex: 3,
  },
});
