import React from 'react';
// @ts-ignore
import {ActivityIndicator, Pressable} from 'react-native';
import {colors} from '../Core/theme/colors';
import {Icon} from '../Icon';
import {Text} from '../Text';

import {styles} from './styles';
import {loadingColor} from "../Button/styles";

export interface BottomSheetItemProps {
  title: string
  titleColor?: string
  iconName: string
  iconColor?: string
  divider?: boolean
  loading?: boolean
  onPress?: () => void;
}

export const BottomSheetItem = ({
  title,
  titleColor = colors.text.primary,
  iconName,
  iconColor = colors.text.secondary,
  divider = true,
  loading = false,
  onPress = () => console.info('Please attach a method to this component'),
}: BottomSheetItemProps) => {
  const containerStyle = divider ? styles.containerDivider : styles.container;
  return (
    <Pressable style={containerStyle} onPress={onPress}>
      <Icon
        name={iconName}
        color={iconColor}
        style={styles.iconStyle}
        styleContainer={styles.iconContainer}
      />
      <Text numberOfLines={1} color={titleColor} style={styles.textContainer}>
        {title}
      </Text>
      <ActivityIndicator style={styles.loading} color={iconColor} animating={loading} />
    </Pressable>
  );
}
