import React, {FunctionComponent, ReactElement} from 'react';
// @ts-ignore
import {TextInputProperties, View} from 'react-native';

import {styles} from './styles';
import {Text} from '../Text';


interface ListHeaderProps extends TextInputProperties {
  columns: string[]
}

export const ListHeader: FunctionComponent<ListHeaderProps> = ({
  columns,
}) => {
  const rightColumn = [...columns];
  const letTitle = rightColumn.shift();

  return (
    <View style={styles.container}>
      <View style={styles.contentLeft}>
        <Text type="th14">{letTitle}</Text>
      </View>
      <View style={styles.contentRight}>
        {rightColumn.map((value, index) => (
          <View key={`standingValueCol${index}`} style={styles.column}>
            <Text type="th14">{value}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}
