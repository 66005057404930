import React from "react";
import {Text} from "../../../../../Text";

interface FixtureInterface {
  homeTeamGoals: number | null
  awayTeamGoals: number | null
}

export const Goals = ({homeTeamGoals, awayTeamGoals}) => {
  if(homeTeamGoals === null || awayTeamGoals === null) {
    return null;
  }
  return <Text type="title">{homeTeamGoals} - {awayTeamGoals}</Text>
}
