// @ts-ignore
import {StyleSheet, TextView} from 'react-native';

import {colors} from "../Core/theme/colors";
import {fonts} from "../Core/theme/fonts";
import {has} from "../../utils";


interface Style {
  text: TextView
  title: TextView
  body: TextView
  info: TextView
}


export const styles: Style = StyleSheet.create<Style>({
  text: {
    ...fonts.regular,
    color: colors.text.primary,
  },
  title: {
    fontWeight: 'bold',
  },
  titleFooter: {
    fontWeight: '900',
  },
  body: {
    fontWeight: '500',
  },
  info: {
    fontWeight: '500',
    color: colors.text.secondary,
  },

  /* temporary headers */
  tH2: {
    ...fonts.regular,
    color: colors.text.secondary,
    fontSize: 10,
  },
  th0: {
    fontFamily: 'Inter-Black',
    fontWeight: '900',
    fontSize: 24,
    color: colors.text.primary
  },
  th1: {
    fontFamily: 'Inter',
    fontSize: 20,
    color: colors.text.primary
  },
  th2: {
    fontFamily: 'Inter',
    fontSize: 18,
    color: colors.text.primary
  },
  th3: {
    fontFamily: 'Inter',
    fontSize: 17,
    color: colors.text.primary
  },
  th4: {
    fontFamily: 'Inter',
    fontWeight: '900',
    fontSize: 16,
    color: colors.text.primary
  },
  th5: {
    fontFamily: 'Inter',
    fontSize: 14,
    color: colors.text.primary
  },
  th6: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 12,
    letterSpacing: 1.1,
    color: colors.text.primary,
  },
  th7: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.text.primary,
  },
  th8: {
    fontFamily: 'Inter',
    fontSize: 12,
    color: colors.text.primary,
  },
  th9: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontSize: 12,
    color: colors.text.primary,
  },
  th10: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontSize: 12,
    color: colors.text.primary,
  },
  th11: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 10,
    color: colors.text.primary,
  },
  th12: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 9,
    color: colors.text.primary,
  },
  th13: {
    fontFamily: 'Inter',
    fontStyle: 'italic',
    fontSize: 10,
    color: colors.text.primary,
  },
  th14: {
    fontFamily: 'Inter',
    fontWeight: '500',
    fontSize: 8,
    letterSpacing: 0.9,
    color: colors.grey["700"],
  },
  th15: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: 8,
    color: colors.text.primary,
  },
  th16: {
    fontFamily: 'Inter',
    fontSize: 10,
    color: colors.primary.main,
  },
  th17: {
    fontFamily: 'Inter',
    fontSize: 10,
    color: colors.text.secondary,
  },
});

export const fontType: TextView = (type: string) => {
  let textStyle: TextView = {};
  if (has.call(styles, type)) {
    textStyle = styles[type];
  }
  return textStyle;
}

export const fontColor: TextView = (color: string) => {
  let returnColor = {}
  if (color) {
    returnColor = {
      color,
    };
  }
  return returnColor;
}
