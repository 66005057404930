import React, {FunctionComponent} from 'react';
// @ts-ignore
import {View} from 'react-native';

import {styles} from './styles';
import {Text} from '../../../Text';


interface StandingValuesProps {
  values?: number[],
}

export const StandingValues: FunctionComponent<StandingValuesProps> = ({
  values = [],
}) => {
  if (!values || values.length === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      {values.map((value, index) => (
        <View key={`standingValueCol${index}`} style={styles.content}>
          <Text type="th8">{value}</Text>
        </View>
      ))}
    </View>
  );
}
