import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {colors} from "../../../../../Core/theme/colors";
import {VenueType} from "../../../../../Core/types/Venue";

import {VenueName} from "./Venue";
import {CityName} from "./City";

interface VenueProps {
  venue: VenueType | null
  onPressVenue?: () => void
}

function City({city}) {
  return <Text type="body" color={colors.text.secondary}> · {city.name}</Text>
}

export const Venue: FunctionComponent<VenueProps> = ({
  venue,
  onPressVenue,
}) => {
  if (!venue) {
    return null;
  }
  const {city} = venue;
  return (
    <View style={styles.container}>
      <VenueName venue={venue} />
      <CityName city={city} />
    </View>
  )
}
