// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';
import {colors} from "../../../Core/theme/colors";

interface Style {
  container: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginHorizontal: 20,
    marginBottom: 20,
    backgroundColor: colors.grey["100"],
    borderColor: colors.grey["500"],
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 15,
    borderStyle: 'dashed',
    height: 225,
    justifyContent: 'center',
  },
});
