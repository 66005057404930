// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import { colors } from '../Core/theme/colors';
import { fonts } from '../Core/theme/fonts';

interface Style {
  container: ViewStyle
  input: TextStyle
  icon: ViewStyle
  iconClear: ViewStyle
  inputIconPadding: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    borderRadius: 3,
    borderWidth: 1,
    borderColor: colors.text.secondary,
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    fontFamily: fonts.regular.fontFamily,
    fontStyle: fonts.regular.fontStyle,
    fontWeight: fonts.regular.fontWeight,
    fontSize: 14,
    color: colors.text.primary,
    flex: 1,
    paddingHorizontal: 10,
  },
  icon: {
    paddingHorizontal: 5,
  },
  iconClear: {
    paddingRight: 5,
  },
});
