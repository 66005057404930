import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Card } from '@components/Card';
import { Button } from '@components/Button';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Card,
  Button,
  React
};