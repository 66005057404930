// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';


import { colors } from '../Core/theme/colors';
import { fonts } from '../Core/theme/fonts';

interface Style {
  container: ViewStyle
  containerRounded: ViewStyle
  title: TextStyle
  titleOutline: ViewStyle
  titleClear: TextStyle
  titleMedium: TextStyle
  titleSmall: TextStyle
  titleDisabled: TextStyle
  button: ViewStyle
  buttonOutline: ViewStyle
  buttonClear: ViewStyle
  buttonMedium: ViewStyle
  buttonSmall: ViewStyle
  buttonDisabled: ViewStyle
  loading: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    overflow: 'hidden',
    borderRadius: 3,
  },
  containerRounded: {
    borderRadius: 50,
  },
  title: {
    fontSize: 12,
    color: '#FFFFFF',
    fontFamily: fonts.regular.fontFamily,
    fontStyle: fonts.regular.fontStyle,
    fontWeight: fonts.regular.fontWeight,
  },
  titleOutline: {
    color: colors.primary.main,
  },
  titleClear: {
    color: colors.primary.main,
  },
  titleSmall: {
    fontSize: 9,
    fontWeight: 'bold',
  },
  titleMedium: {
    fontSize: 10,
    fontWeight: 'normal',
  },
  titleDisabled: {
    color: '#FFFFFF',
  },
  button: {
    backgroundColor: colors.actions.active,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 15,
  },
  buttonOutline: {
    backgroundColor: colors.background.white,
    borderColor: colors.actions.active,
    borderWidth: 1,
    borderRadius: 3,
  },
  buttonClear: {
    backgroundColor: colors.background.white,
  },
  buttonMedium: {
    height: 30,
  },
  buttonSmall: {
    height: 21,
  },
  buttonDisabled: {
    backgroundColor: colors.actions.disabled,
    borderColor: colors.actions.disabled,
    borderWidth: 1,
    borderRadius: 3,
  },
  loading: {
    position: 'absolute',
    right: 10,
    height: 10,
  },
});

export const defaultButtonStyle: ViewStyle = (type: string, size: string, disabled: boolean): ViewStyle => {
  let styleStack: ViewStyle[] = [];
  if (type === 'outline') {
    styleStack.push(styles.buttonOutline);
  }
  if (type === 'clear') {
    styleStack.push(styles.buttonClear);
  }
  if (size === 'small') {
    styleStack.push(styles.buttonSmall);
  }
  if (size === 'medium') {
    styleStack.push(styles.buttonMedium);
  }
  if (disabled) {
    styleStack.push(styles.buttonDisabled);
  }
  return styleStack;
}

export const defaultTitleStyle: TextStyle = (type: string, size: string, disabled: boolean): TextStyle => {
  let styleStack: TextStyle[] = [];
  if (type === 'outline') {
    styleStack.push(styles.titleOutline);
  }
  if (type === 'clear') {
    styleStack.push(styles.titleClear);
  }
  if (size === 'small') {
    styleStack.push(styles.titleSmall);
  }
  if (size === 'medium') {
    styleStack.push(styles.titleMedium);
  }
  if (disabled) {
    styleStack.push(styles.titleDisabled);
  }
  return styleStack;
}

export const loadingColor = (type: string): string => {
  let indicatorColor = '#FFFFFF';
  if (type === 'outline') {
    indicatorColor = colors.primary.main;
  }
  return indicatorColor;
}
