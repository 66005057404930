import React, {ElementType, FunctionComponent} from 'react';

import {Header} from "./Header";
import {CompetitionType} from "../Core/types/Competition";
import {CompetitionAvatar} from "../Avatar/CompetitionAvatar";

const defaultCoverUri = require('./assets/competitionCover.jpg');

interface HeaderUserProps {
  competition: CompetitionType
  onPressBackButton?: () => void
  ImageComponent?: ElementType,
}

export const HeaderCompetition: FunctionComponent<HeaderUserProps> = ({
  competition,
  onPressBackButton,
  ImageComponent,
}) => {
  return (
    <Header
      avatar={<CompetitionAvatar competition={competition} size="xxLarge" rounded />}
      title={competition.name}
      coverUri={defaultCoverUri}
      onPressBackButton={onPressBackButton}
      ImageComponent={ImageComponent}
    />
  )
}
