import React, {FunctionComponent} from 'react';
// @ts-ignore
import {View} from 'react-native';

import {styles} from './styles';
import {colors} from '../../../Core/theme/colors';
import {Icon} from '../../../Icon';
import {Text} from '../../../Text';

const iconColor = new Map();
iconColor.set('rank1', colors.primary.main);
iconColor.set('rank2', colors.text.primary);
iconColor.set('rank3', colors.text.secondary);

function getContainerStyle(position) {
  if (position < 10) {
    return styles.container1;
  }
  if (position < 100) {
    return styles.container2;
  }
  if (position < 1000) {
    return styles.container3;
  }
  return styles.container;
}

interface RankProps {
  position?: number,
}

export const Rank: FunctionComponent<RankProps> = ({
                                                     position,
                                                   }: RankProps) => {
  if (!position) {
    return null;
  }

  if (position > 3) {
    return (
      <View style={getContainerStyle(position)}>
        <Text>{position}</Text>
      </View>
    )
  }
  const iconName = `rank${position}`;
  return (
    <View style={styles.rankContainer}>
      <Icon name={iconName} color={iconColor.get(iconName)} style={styles.iconStyle} />
    </View>
  );
}
