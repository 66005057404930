// @ts-ignore
import {FunctionComponent, ComponentProps} from 'react-native';
import {UserType} from "../components/Core/types/User";
import {GenderType} from "../components/Core/types/Gender";

export const has = Object.prototype.hasOwnProperty;

export const userGenderIconName = (gender: GenderType) => {
  return gender.name === "female" ? "avatar-female" : "avatar-male";
}

export const userIconName = (user: UserType) => {
  let name = "avatar";

  if (user && user.gender) {
    name = userGenderIconName(user.gender);
  }
  return name;
}

export const userProfileUri = (user: UserType) => {
  let uri = user.profilePicture;
  if (!uri) {
    uri = userIconName(user);
  }
  return uri;
}
