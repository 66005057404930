// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginHorizontal: 20,
    marginVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
  },
  content: {
    flex: 1,
    paddingHorizontal: 10,
  },
});
