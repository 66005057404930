import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";

export const Message: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <View style={styles.content} />
    </View>
  )
};
