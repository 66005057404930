import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';
import {Icon} from "../../../Icon";

import {styles} from "./styles";
import {colors} from "../../../Core/theme/colors";
import {Message} from '../Message'

export const Header: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <View style={styles.avatar} />
      <View style={styles.content}>
        <View style={styles.username} />
        <Message />
      </View>
      <View>
        <Icon name="more" color={colors.text.secondary} />
      </View>
    </View>
  )
}
