// @ts-ignore
import {StyleSheet, ViewStyle, TextStyle} from 'react-native';

interface Style {
  container: ViewStyle
  content: ViewStyle
  hide: ViewStyle
  text: TextStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginBottom: 20,
    flex: 1,
  },
  content: {},
  hide: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    height: 1,
  },
  text: {
    lineHeight: 18,
  }
});
