import React, {FunctionComponent, ElementType} from 'react';
// @ts-ignore
import {Image} from 'react-native';

import {IconAvatar} from './IconAvatar';
import {ImageAvatar} from './ImageAvatar';

export interface AvatarProps {
  /**
   * Image source (optional)
   */
  uri?: string,

  /**
   * Icon Name that it will be displayed in case no uri is provided
   */
  icon?: string

  /**
   * Avatar size.

   * - xSmall: 20 x 20
   * - small: 24 x 24
   * - medium: 36 x 36
   * - large: 40 x 40
   * - xLarge: 48 x 48
   * - xxLarge: 96 x 96
   *
   * @default medium
   */
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

  /**
   * Makes the avatar circular
   * - true
   * - false
   *
   * @default false
   * */
  rounded?: boolean,

  /**
   * Custom ImageComponent for Avatar
   * @default Image
   */
  ImageComponent?: ElementType,

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void;
}

export const Avatar: FunctionComponent<AvatarProps> = ({
  uri,
  icon,
  size = 'medium',
  rounded = false,
  ImageComponent = Image,
  onPress,
}) => {
  if (uri) {
    return <ImageAvatar ImageComponent={ImageComponent} size={size} rounded={rounded} onPress={onPress} uri={uri} />
  }
  if (icon) {
    return <IconAvatar name={icon} onPress={onPress} rounded={rounded} size={size} />;
  }
  return null;
}
