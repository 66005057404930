import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {colors} from "../../../../../Core/theme/colors";
import {VenueType} from "../../../../../Core/types/Venue";
import {Icon} from "../../../../../Icon";
import {CityType} from "../../../../../Core/types/City";
import {CountryType} from "../../../../../Core/types/Country";


interface VenueProps {
  country: CountryType | null
}

export const CountryName: FunctionComponent<VenueProps> = ({country}) => {
  if (!country) {
    return null;
  }

  return (
    <View style={styles.contentContainer}>
      <Text type="body" color={colors.text.secondary}>, {country.name}</Text>
    </View>
  )
}
