import React, {ElementType, FunctionComponent} from 'react';

import {UserType} from "../Core/types/User";
import {Header} from "./Header";
import {UserAvatar} from "../Avatar";

const defaultCoverUri = require('./assets/userCover.jpg');

interface HeaderUserProps {
  user: UserType
  onPressBackButton?: () => void
  ImageComponent?: ElementType,
}

export const HeaderUser: FunctionComponent<HeaderUserProps> = ({
  user,
  onPressBackButton,
  ImageComponent,
}) => {
  const coverUri = user.coverPicture ? {uri: user.coverPicture} : defaultCoverUri;
  return (
    <Header
      title={user.name}
      avatar={<UserAvatar user={user} size="xxLarge" rounded />}
      coverUri={coverUri}
      onPressBackButton={onPressBackButton}
      ImageComponent={ImageComponent}
    />
  )
}
