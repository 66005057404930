import React, {FunctionComponent, ReactNode} from 'react';
// @ts-ignore
import {Pressable, View} from 'react-native';

import { colors } from '../Core/theme/colors';
import {styles} from './styles';
import {Icon} from '../Icon';
import {Text} from '../Text';


interface BoxProps {
  title: string

  /**
   * Action title that will be displayed if onPress is defined
   */
  onPressTitle?: string

  /**
   * Callback function when pressing on action. (optional)
   */
  onPress?: () => void

  /**
   * Box background can be dark grey or light grey
   */
  theme?: 'dark' | 'light'

  /**
   * React Node that will be render inside the box
   */
  children: ReactNode,
}

export const Box: FunctionComponent<BoxProps> = ({
  title,
  onPressTitle,
  theme = 'dark',
  onPress,
  children,
}) => {
  const containerStyle = theme === 'dark' ? styles.containerDark : styles.containerLight;
  return (
    <View style={containerStyle}>
      <View style={styles.header}>
        <Text type="th11">{title}</Text>
        {onPress && (
          <Pressable onPress={onPress} style={styles.actionContainer}>
            <Text type="th16">{onPressTitle}</Text>
            <Icon color={colors.primary.main} name="chevron-right" size="small" />
          </Pressable>
        )}
      </View>
      {children}
    </View>
  )
}
