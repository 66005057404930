// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';


import { colors } from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  containerDivider: ViewStyle
  loading: ViewStyle
  iconStyle: TextStyle
  iconContainer: ViewStyle
  textContainer: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
    height: 46,
    alignItems: 'center',
  },
  containerDivider: {
    flexDirection: 'row',
    height: 46,
    alignItems: 'center',
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: colors.grey['300'],
  },
  textContainer: {
    flex: 1,
  },
  loading: {
    paddingHorizontal: 10,
  },
  iconStyle: {
    fontSize: 12,
  },
  iconContainer: {
    paddingHorizontal: 10,
  },
});
