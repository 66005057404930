import React, {FunctionComponent, ReactNode} from 'react';
import {
  ActivityIndicator,
  Platform,
  View,
  Text,
  TouchableNativeFeedback,
  TouchableOpacity,
  // @ts-ignore
} from 'react-native';

import {colors} from '../Core/theme/colors';
import {styles, defaultButtonStyle, defaultTitleStyle, loadingColor} from './styles';

export interface ButtonProps {
  /**
   * button title
   */
  title?: string

  /**
   * Type of button
   * @default solid
   */
  type?: 'solid' | 'clear' | 'outline';

  /**
   * Size of button
   * @default large
   */
  size?: 'large' | 'medium' | 'small';

  /**
   * Displays an icon to the left of the button
   * @default null
   */
  icon?: ReactNode;

  /**
   * Component for user interaction
   * @default TouchableOpacity (ios) or TouchableNativeFeedback (android)
   */
  Touchable?: ReactNode;

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void;

  /**
   *  Display a loading spinner
   *  @default false
   */
  loading?: boolean;

  /**
   *  Rounded button
   *  @default false
   */
  rounded?: boolean;

  /**
   *  Disables user interaction
   *  @default false
   */
  disabled?: boolean;

  /**
   * add additional styling for button component
   */
  buttonStyle?: object;

  /**
   * add additional styling for button container
   */
  containerStyle?: object;

  /**
   * add additional styling for title component
   */
  titleStyle?: object;

  /**
   * add additional styling for loading component
   */
  loadingStyle?: object;

  /**
   * Activity Indicator color
   */
  activityIndicatorColor?: string;
}

export const Button: FunctionComponent<ButtonProps> = ({
  title,
  type = 'solid',
  size = 'large',
  icon = null,
  Touchable = Platform.select({
    android: TouchableNativeFeedback,
    default: TouchableOpacity,
  }),
  onPress = () => console.info('Please attach a method to this component'),
  loading = false,
  disabled = false,
  rounded = false,
  buttonStyle = {},
  containerStyle = {},
  titleStyle = {},
  loadingStyle,
  activityIndicatorColor
}: ButtonProps) => {

  const handleOnPress = () => {
    if (!loading && !disabled) {
      onPress();
    }
  }

  const TouchableComponent = disabled || loading ? View : Touchable;
  const containerRounded = rounded ? styles.containerRounded : {};

  if (loading && size === 'small') {
    return (
      <View style={[styles.container, containerRounded]}>
        <ActivityIndicator animating={loading} color={colors.text.secondary} />
      </View>
    )
  }

  return (
    <View style={[styles.container, containerRounded, containerStyle]}>
      <TouchableComponent onPress={handleOnPress}>
        <View style={[
          styles.button,
          defaultButtonStyle(type, size, disabled),
          containerRounded,
          buttonStyle
        ]}>
          {icon}
          {title && (<Text style={[
            styles.title,
            defaultTitleStyle(type, size, disabled),
            titleStyle
          ]}>{title}</Text>)}
          {loading &&
          <ActivityIndicator
            style={loadingStyle ? loadingStyle : styles.loading}
            color={activityIndicatorColor ? activityIndicatorColor : loadingColor(type)}
            animating={loading}
          />
          }
        </View>
      </TouchableComponent>
    </View>
  );
};
