import React, {FunctionComponent} from "react";

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {CompetitionType} from "../../../../../Core/types/Competition";
import {colors} from "../../../../../Core/theme/colors";

interface CompetitionNameInterface {
  competition?: CompetitionType
}

export const CompetitionName: FunctionComponent<CompetitionNameInterface> = ({competition}) => {
  if (!competition) {
    return null;
  }
  return (
    <Text type="body" color={colors.grey["800"]} numberOfLines={1} style={styles.contentContainer}>
      {competition.name}
    </Text>
  )
}
