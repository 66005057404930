import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { HeaderUser, HeaderVenue, HeaderTeam, HeaderCompetition } from '@components';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  HeaderUser,
  HeaderVenue,
  HeaderTeam,
  HeaderCompetition,
  React
};