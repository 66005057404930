import React, {FunctionComponent} from 'react';
import {TeamType} from "../Core/types/Team";
import {Avatar, AvatarProps} from './Avatar';

interface TeamAvatarProps extends AvatarProps {
  /**
   * User object
   */
  team: TeamType,
}

export const TeamAvatar: FunctionComponent<TeamAvatarProps> = ({
  team,
  ...props
}) => {
  return <Avatar uri={team.logo} {...props} icon="team" />;
}
