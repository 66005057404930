import React, {FunctionComponent, ReactElement} from 'react';
// @ts-ignore
import {Image} from 'react-native';

import {styles} from './styles';

interface FlagProps {
  code: string
  size?: 'small'
}

export const Flag: FunctionComponent<FlagProps> = ({
  code,
  size= 'small',
}) => {
  const lowerCode = code.toLowerCase();
  const uri = `http://cdn.thefansarena.com/images/flags/${lowerCode}.png`;
  return (
    <Image source={{uri}} style={styles[size]} resizeMode="contain" />
  )
}
