export const comment1 = {
  user: {
    id: 'id1',
    name: 'Marcel Popescu',
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id.',
}
export const comment2 = {
  user: {
    id: 'id5',
    name: 'Marcel Popescu',
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id.',
}

export const comment3 = {
  id: 'id3',
  user: {
    name: 'Marcel Popescu',
    profilePicture: 'https://picsum.photos/200',
    gender: {
      name: 'male'
    },
  },
  message: 'Vivamus et porttitor mauris. Morbi elementum condimentum arcu, sit amet aliquet orci blandit id. Maecenas ante urna, congue quis odio vel, maximus lacinia ipsum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus id sagittis risus, vel volutpat ipsum. Aliquam erat volutpat. Vestibulum odio magna, blandit tincidunt blandit vitae, molestie sit amet neque. Nulla facilisi. Curabitur at semper tellus, at dapibus mauris. Quisque ut sem quam.',
}

