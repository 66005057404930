// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  imageFocus: ViewStyle
  list: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    borderRadius: 15,
    overflow: 'hidden',
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  list: {
    flex: 1,
  }
});
