import React, {FunctionComponent} from 'react';
import {Text} from '../../../Text';

interface TitleProps {
  title?: string
  color?: string
}

export const Title: FunctionComponent<TitleProps> = ({
  title,
  color,
}: TitleProps) => {
  if (!title) {
    return null;
  }
  return (
    <Text type="th8" numberOfLines={1} color={color}>{title}</Text>
  );
}
