import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { PostPlaceholder } from '@components/PostPlaceholder';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  PostPlaceholder,
  React
};