import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { UserAvatar } from '@components/Avatar/UserAvatar';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  UserAvatar,
  React
};