import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {Reactions} from './components/Reactions'
import {Comments} from './components/Comments'

import {styles} from "./styles";
import {ReactionType} from "../../../Core/types/Reaction";

interface FooterInterface {
  reactionsCount?: number
  commentsCount?: number
  viewerReaction?: ReactionType[]
  onPressReaction?: () => void
  onPressReactionCount?: () => void
  onPressComments?: () => void
}

export const Footer: FunctionComponent<FooterInterface> = ({
  reactionsCount,
  commentsCount,
  viewerReaction,
  onPressReaction,
  onPressReactionCount,
  onPressComments,
}) => {
  return (
    <View style={styles.container}>
      <Reactions
        reactionsCount={reactionsCount}
        viewerReaction={viewerReaction}
        onPressReaction={onPressReaction}
        onPressReactionCount={onPressReactionCount}
      />
      <Comments commentsCount={commentsCount} onPressComments={onPressComments} />
    </View>
  )
}
