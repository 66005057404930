// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';
import {colors} from "../../../Core/theme/colors";

interface Style {
  container: ViewStyle
  avatar: ViewStyle
  content: ViewStyle
  username: ViewStyle
  venue: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginTop: 20,
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
  },
  content: {
    flex: 1,
    paddingHorizontal: 5,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 30,
    borderColor: colors.grey["500"],
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'dashed',
    backgroundColor: colors.grey["100"],
    marginRight: 5,
  },
  username: {
    width: 119,
    height: 7,
    borderRadius: 1,
    borderColor: colors.grey["500"],
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'dashed',
    backgroundColor: colors.grey["100"],
  },
  venue: {
    width: 82,
    height: 7,
    borderRadius: 1,
    borderColor: colors.grey["500"],
    borderWidth: StyleSheet.hairlineWidth,
    borderStyle: 'dashed',
    backgroundColor: colors.grey["100"],
    marginTop: 6,
  },
});
