import React, {FunctionComponent} from "react";
// @ts-ignore
import {Platform} from 'react-native';

import {styles} from "./styles";
import {Text} from "../../../../../Text";
import {StageType} from "../../../../../Core/types/Stage";
import {Icon} from "../../../../../Icon";
import {colors} from "../../../../../Core/theme/colors";


interface StageNameInterface {
  stage: StageType | null
}

export const StageName: FunctionComponent<StageNameInterface> = ({stage}) => {
  if (!stage) {
    return null;
  }

  const iconName = Platform.OS === 'android' ? 'point-bottom' : 'point';

  return (
    <Text style={styles.contentContainer}>
      <Icon name={iconName} color={colors.grey["800"]} styleContainer={styles.iconContainerStyle} />
      <Text type="body" color={colors.grey["800"]} numberOfLines={1}>{stage.name}</Text>
    </Text>

  )
}
