// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

import { colors } from '../Core/theme/colors';
import { fonts } from '../Core/theme/fonts';

interface Style {
  container: ViewStyle
  containerWithBorder: ViewStyle
  content: ViewStyle
  contentLeft: ViewStyle
  contentLeftWithPosition: ViewStyle
  contentCenter: ViewStyle
  contentRight: ViewStyle
  contentCenterTop: ViewStyle
  contentCenterBottom: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
  },
  containerWithBorder: {
    borderColor: colors.text.secondary,
    borderBottomWidth: 1,
  },
  content: {
    height: 64,
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  contentLeft: {
    width: 36,
    height: 36,
    borderRadius: 30,
    backgroundColor: colors.grey["200"],
  },
  contentCenter: {
    flex: 1,
    height: 64,
    paddingLeft: 10,
    justifyContent: 'center',
  },
  contentRight: {
    width: 82,
    height: 30,
    borderRadius: 15,
    backgroundColor: colors.grey["200"],
  },
  contentCenterTop: {
    width: 112,
    height: 11,
    borderRadius: 1,
    backgroundColor: colors.grey["200"],
  },
  contentCenterBottom: {
    width: 72,
    height: 7,
    borderRadius: 1,
    backgroundColor: colors.grey["200"],
    marginTop: 4,
  },
});
