// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';
import {colors} from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    paddingHorizontal: 15,
    paddingTop: 20,
    backgroundColor: colors.background.default,
    flex: 1,
  },
  content: {
    // padding: 20,
    borderRadius: 15,
    backgroundColor: colors.background.white,

    shadowColor: colors.grey["500"],
    shadowOffset: {
      width: 0,
      height: 23,
    },
    shadowOpacity: 0.11,
    shadowRadius: 23.00,
    elevation: 1,
  }
});
