// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

import {colors} from '../Core/theme/colors';

interface Style {
  container: ViewStyle
  contentTitle: ViewStyle
  contentValue: ViewStyle
  contentRight: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    marginBottom: 1,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: colors.grey["200"],
    flexDirection: 'row',
    height: 46,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  contentTitle: {
    width: 125
  },
  contentValue: {
    flex: 1,
  },
  contentRight: {
    alignItems: 'flex-end',
  },
});
