// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

import {colors} from '../../../../../Core/theme/colors';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    backgroundColor: colors.background.white,
    overflow: 'hidden',
    borderRadius: 15,
    position: 'absolute',
    bottom: 5,
    alignSelf: 'center',
    height: 30,
  },
  content: {
    borderRadius: 15,
    height: 30,
    borderWidth: 1,
    borderColor: colors.grey['400'],
    paddingHorizontal: 15,
    flexDirection: 'row',
    alignItems: 'center',
  },
});
