import React, {ElementType, FunctionComponent} from "react";
// @ts-ignore
import {Image, View, Pressable} from 'react-native';
import {Message} from '../Message';
import {UserAvatar} from "../../../Avatar";
import {Text} from "../../../Text";
import {Icon} from "../../../Icon";

import {styles} from "./styles";
import {UserType} from "../../../Core/types/User";
import {colors} from "../../../Core/theme/colors";

interface HeaderProps {
  authUser: UserType,
  user: UserType
  message: string
  ImageComponent?: ElementType,
  onPressUser?: () => void
  onPressOptions?: () => void
}

export const Header: FunctionComponent<HeaderProps> = ({
  user,
  message,
  authUser,
  ImageComponent = Image,
  onPressUser,
  onPressOptions,
}) => {
  const UserContainerComponent = onPressUser ? Pressable : View;
  const OptionsContainerComponent = onPressOptions ? Pressable : View;

  const showOptions = (user.id === authUser.id || authUser.isAdmin);
  return (
    <View style={styles.container}>
      <UserContainerComponent onPress={onPressUser} style={styles.avatar}>
        <UserAvatar user={user} size="large" rounded ImageComponent={ImageComponent} />
      </UserContainerComponent>
      <View style={styles.content}>
        <UserContainerComponent onPress={onPressUser}>
          <Text type="title" numberOfLines={1}>{user.name}</Text>
        </UserContainerComponent>
        <Message message={message} />
      </View>
      {showOptions && (
        <OptionsContainerComponent onPress={onPressOptions}>
          <Icon name="more" color={colors.text.secondary} />
        </OptionsContainerComponent>
      )}
    </View>
  )
}
