import React, {ElementType, FunctionComponent} from 'react';

import {Header} from "./Header";
import {TeamAvatar} from "../Avatar/TeamAvatar";
import {TeamType} from "../Core/types/Team";

const defaultCoverUri = require('./assets/teamCover.jpg');

interface HeaderTeamProps {
  team: TeamType
  onPressBackButton?: () => void
  ImageComponent?: ElementType,
}

export const HeaderTeam: FunctionComponent<HeaderTeamProps> = ({
  team,
  onPressBackButton,
  ImageComponent,
}) => {
  return (
    <Header
      title={team.name}
      avatar={<TeamAvatar team={team} size="xxLarge" rounded />}
      coverUri={defaultCoverUri}
      onPressBackButton={onPressBackButton}
      ImageComponent={ImageComponent}
    />
  )
}
