// @ts-ignore
import {StyleSheet} from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 6,
    borderColor: '#e1e1e1',
    padding: 20,
  },
  box: {
    borderColor: '#F1F2F6',
    borderWidth: 1,
    width: 50,
    height: 50,
    borderRadius: 10,
    margin: 10
  },
});
