import React, {FunctionComponent} from 'react';
// @ts-ignore
import {View} from 'react-native';
import {createIconSetFromIcoMoon} from 'react-native-vector-icons';


import icoMoonConfig from './selection.json';
import {styles} from './styles';
import {colors} from '../Core/theme/colors';


export const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'TheFans', 'TheFans.ttf');

interface IconProps {
  /**
   * Name of icon
   */
  name: string

  /**
   * Size of icon
   * @default medium
   */
  size?: 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge'

  /**
   * Color of icon
   */
  color?: string

  /**
   * Additional styling to icon
   * @default {}
   */
  style?: object

  /**
   * Additional styling to icon
   * @default {}
   */
  styleContainer?: object

  /**
   * Callback function when pressing component. (optional)
   */
  onPress?: () => void;
}

export const Icon: FunctionComponent<IconProps> = ({
  name,
  size = 'large',
  color = colors.text.primary,
  style = {},
  styleContainer = {},
  onPress
}) => {
  return (
    <View style={[styles.container, styleContainer]}>
      <IconMoon name={name} style={[styles[size], style]} color={color} onPress={onPress} />
    </View>
  )
}
