import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { Icon } from '@components/Icon';
import * as React from 'react';
export default {
  Playground,
  Props,
  View,
  Icon,
  React
};