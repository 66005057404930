import React, {ElementType, FunctionComponent, ReactNode} from 'react';
// @ts-ignore
import {Pressable, View} from 'react-native';

import {colors} from '../Core/theme/colors';
import {styles} from './styles';
import {Icon} from '../Icon';
import {Text} from '../Text';
import {Avatar} from "../Avatar/Avatar";
import {UserAvatar} from "../Avatar";
import {UserType} from "../Core/types/User";
import {Button} from "../Button";


interface CardProps {
  title: string
  subtitle?: string
  user: UserType
  active?: boolean
  action?: ReactNode,
  onClose?: () => void,
  /**
   * Custom ImageComponent for Avatar
   */
  ImageComponent?: ElementType;
}

export const Card: FunctionComponent<CardProps> = ({
  title,
  subtitle,
  user,
  active = false,
  action = null,
  onClose,
  ImageComponent,
}) => {
  return (
    <View style={[styles.container, active ? styles.containerActive : {}]}>
      {onClose && (<Icon name="close" onPress={onClose} styleContainer={styles.iconContainer} size="small" color={colors.grey["500"]} />)}
      <View style={styles.avatarContainer}>
        <UserAvatar user={user} size="xLarge" rounded ImageComponent={ImageComponent} />
      </View>
      <Text type="th8" numberOfLines={1}>{title}</Text>
      {subtitle && (<Text type="th17" numberOfLines={1}>{subtitle}</Text>)}
      <View style={styles.buttonContainer}>
        {action}
      </View>
    </View>
  )
}
