// @ts-ignore
import {Platform, StyleSheet, ViewStyle, TextStyle} from 'react-native';

import {colors} from "../../../../../Core/theme/colors";

interface Style {
  competition: ViewStyle
  contentContainer: TextStyle
  iconContainerStyle: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  competition: {
    height: 32,
    backgroundColor: colors.grey["200"],
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  contentContainer: {
    ...Platform.select({
      ios: {
        lineHeight: 32,
      },
    }),
  },
  iconContainerStyle: {
    ...Platform.select({
      android: {
        padding: 2,
      },
    })
  },
});
