// @ts-ignore
import {StyleSheet, TextStyle, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  container1: ViewStyle
  container2: ViewStyle
  container3: ViewStyle
  rankContainer: ViewStyle
  iconStyle: TextStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    paddingRight: 5,
  },
  container1: {
    width: 30,
    paddingLeft: 7,
  },
  container2: {
    width: 30,
    paddingLeft: 4,
  },
  container3: {
    width: 30,
  },
  container4: {
    width: 30,
  },
  rankContainer: {
    width: 35,
    marginLeft: -5,
  },
  iconStyle: {
    fontSize: 24,
  },
});
