import React, {FunctionComponent, ReactNode} from 'react';

// @ts-ignore
import {Animated, Text as RNText, TextProps} from 'react-native';
import {styles, fontType, fontColor} from './styles';

interface Props extends TextProps {
  type?: 'title' | 'titleFooter' | 'body' | 'info' | 'tH2' | 'th0' | 'th1' | 'th2' | 'th3' | 'th4' | 'th5' | 'th6' | 'th7' | 'th8' | 'th9' | 'th10' | 'th11' | 'th12' | 'th13' | 'th14' | 'th15' | 'th16' | 'th17'
  color?: string
  numberOfLines?: number
  children: ReactNode,
  style?: Object
  animated?: boolean
}


export const Text: FunctionComponent<Props> = ({
  type,
  color,
  children,
  numberOfLines,
  style = {},
  animated = false,
  ...rest
}) => {
  const Component = animated ? Animated.Text : RNText;
  return (
    <Component{...rest} style={[styles.text, fontType(type), fontColor(color), style]} numberOfLines={numberOfLines}>
      {children}
    </Component>
  )
}
