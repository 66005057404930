// @ts-ignore
import {StyleSheet, ViewStyle} from 'react-native';

interface Style {
  container: ViewStyle
  content: ViewStyle
}

export const styles: Style = StyleSheet.create<Style>({
  container: {
    flexDirection: 'row',
  },
  content: {
    width: 31,
    paddingHorizontal: 5,
    alignItems: 'flex-end',
  },
});
