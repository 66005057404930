import React, {FunctionComponent} from "react";
// @ts-ignore
import {View} from 'react-native';

import {Reactions} from './components/Reactions'
import {Comments} from './components/Comments'

import {styles} from "./styles";

export const Footer: FunctionComponent = () => {
  return (
    <View style={styles.container}>
      <Reactions />
      <Comments />
    </View>
  )
}
