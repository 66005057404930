import React, {ElementType} from 'react';

import {styles} from './styles';
import {ImageType} from "../../../../../Core/types/Image";

interface GalleryInterface {
  image: ImageType
  ImageComponent: ElementType,
}

export const ImageItem = ({image, ImageComponent}) => {
  return (
    <ImageComponent source={{uri: image.uri}} style={styles.image} resizeMode="cover" />
  )
}
